import { React, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfolio, meta } from "../../content_option";
import projectData from "../../assets/projects.json";
import ProjectCard from "../../components/project-card/ProjectCard";
import DropdownFilter from "../../components/filter/DropdownFilter";

export const Portfolio = () => {
  const [filter, setFilter] = useState("All");

  const updateFilter= (newValue) => {
    setFilter(newValue);
  }

  const projectDataFinal =
    filter === "All"
      ? projectData
      : projectData.filter((p) => p.category.includes(filter));


  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-1 pt-md-3">
          <Col>
            <h1 className="display-4"> Projects </h1>
          </Col>
          <Col className="w-100">
            <div className="filter-controls">
              <DropdownFilter updatePageFilter={updateFilter} filter={filter}/>
            </div>
            {/* todo: filter component */}
          </Col>
        </Row>
        <hr className="t_border mb-4" />
        <div className="mb-4 po_items_ho">
          {projectDataFinal.map((project) => (
            <ProjectCard key={project.id} projectData={project} />
          ))}
        </div>
      </Container>
    </HelmetProvider>
  );
};
