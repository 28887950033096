import "./CategoryPill.css";
import { FaCircle } from "react-icons/fa";

const CategoryPill = ({ category, color }) => {
  return (
    <div className="pill" >
      <FaCircle color={color}/> {category.toLowerCase()} 
    </div>
  );
};

export default CategoryPill;
