import React, { useState, useRef } from "react";
import "./AudioPlayer.css";

function AudioPlayer(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlayback = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="audio-player">
      <audio
        ref={audioRef}
        src={props.src}
        onEnded={() => setIsPlaying(false)}
      />
      <button
        className={`play-pause-btn ${isPlaying ? "pause" : "play"}`}
        onClick={togglePlayback}
      >
        {isPlaying ? (
          <i className="fas fa-pause"></i>
        ) : (
          <i className="fas fa-play"></i>
        )}
      </button>
    </div>
  );
}

export default AudioPlayer;
