import "./ProjectCard.css";
import Category from "./Category";
import Date from "./Date";
import Title from "./Title";
import Description from "./Description";
import CategoryPill from "./card-header/CategoryPill";
import AudioPlayer from "./AudioPlayer";
import { introdata, meta } from "../../content_option";
import ExternalLinkButton from "./ExternalLinkButton";
import CardHeader from "./card-header/CardHeader";

const ProjectCard = ({ projectData }) => {
  return (
    <div className="po_item card">
      <div className="card__body">
        <Title title={projectData.title} />
        <Date date={projectData.date} />
        <hr></hr>
        <Description description={projectData.description}></Description>
      </div>
      <div className="card__media media">
        {projectData.category === "Music" ? (
          <AudioPlayer src={projectData.media_link}></AudioPlayer>
        ) : projectData.category === "Web" ||
          projectData.category === "Marketing" ||
          projectData.category === "Education" ? (
          <>
            <ExternalLinkButton
              url={projectData.hyper_link}
              label="Link to Website"
            />
          </>
        ) : (
          <img src={projectData.media_link} alt="project media"></img>
        )}
      </div>
      <CardHeader projectData={projectData} />
    </div>
  );
};

export default ProjectCard;
