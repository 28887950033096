import React from 'react';
import './ExternalLinkButton.css'; // Make sure to create this CSS file

const ExternalLinkButton = ({ url, label }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="external-link-btn">
      {label} <span className="link-icon">↗</span>
    </a>
  );
};

export default ExternalLinkButton;