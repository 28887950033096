import "./CardHeader.css";
import CategoryPill from "./CategoryPill";
import { FaCircle } from "react-icons/fa";

const CardHeader = ({ projectData }) => {
  console.log(projectData.category);

  const style = { display: "flex", alignItems: "center" };

  const getColor = (category) => {
    switch (category) {
      case "Music":
        return "magenta";
      case "Video":
        return "blue";
      case "Web":
        return "lime";
      case "Technology":
        return "lime";
      case "Design":
        return "orange";
      case "Education":
        return "brown";
      default:
        return "red"; // Default color if none of the cases match
    }
  };

  const color = getColor(projectData.category)

  return (
    <>

<hr></hr>
    <div className="card__header">
      <CategoryPill
        category={projectData.category}
        color={color}
      ></CategoryPill>
      <div className="card__id">
        {projectData.id}
      </div>
    </div>
    </>
  );
};

export default CardHeader;
