import Dropdown from 'react-bootstrap/Dropdown';
import "./DropdownFilter.css";
import { VscWhitespace } from 'react-icons/vsc';

function DropdownFilter({updatePageFilter, filter, filterCategories}) {

  return (
    <Dropdown>
      <Dropdown.Toggle variant="dark">{filter}</Dropdown.Toggle>

      <Dropdown.Menu variant="dark">
        <Dropdown.Item onClick={() => updatePageFilter('All')}>All</Dropdown.Item>

        <Dropdown.Item onClick={() => updatePageFilter('Music')}>Music</Dropdown.Item>
        <Dropdown.Item onClick={() => updatePageFilter('Web')}>Web</Dropdown.Item>
        <Dropdown.Item onClick={() => updatePageFilter('Design')}>Design</Dropdown.Item>
        <Dropdown.Item onClick={() => updatePageFilter('Video')}>Video</Dropdown.Item>
        <Dropdown.Item onClick={() => updatePageFilter('Marketing')}>Marketing</Dropdown.Item>
        <Dropdown.Item onClick={() => updatePageFilter('Technology')}>Technology</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownFilter;