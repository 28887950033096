const style = { display: "flex", alignItems: "center", gap: "0.25rem" };

const logotext = (
  <div style={style}>
    <div className="circle-container">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
    <span>kamedia</span>
  </div>
);

const meta = {
  title: "kamedia",
  description:
    "we're kali and milan. full-stack creators, innovators, and problem solvers based in the midwest.",
};

const introdata = {
  title: "We're Kali and Milan.",
  animated: {
    first: "We love to create",
    second: "We make cool things",
    third: "Collaborate with us?",
  },
  description:
    "See how we channel our multifaceted creativity into captivating works that span various realms of multimedia.",
  your_img_url: "./image/diorama.png",
};

const dataabout = {
  title: "abit about us",
  aboutme:
    "We are the driving force behind a multifaceted creative studio based in Milwaukee. With a rich blend of talents in art, music, design, web development, and strategic business planning, we offer a holistic approach to helping businesses and individuals achieve their goals. Our unique ability to integrate creative design with practical business solutions allows us to deliver bespoke services that not only stand out aesthetically but also drive tangible results. Whether it's crafting a visually stunning website, devising a comprehensive business strategy, or offering expert consultancy, our passion for innovation and excellence shines through in every project we undertake. Together, we are committed to transforming ideas into realities, guiding our clients towards success in an ever-evolving digital landscape.",
};

const worktimeline = [
  {
    jobtitle: "Date",
    where: "Milwaukee, WI",
    date: "Fall, 2020",
  },
  {
    jobtitle: "Project",
    where: "Keep That Booty Tight",
    date: "Spring, 2021",
  },
  {
    jobtitle: "Video",
    where: "Dalworth Rug Cleaning",
    date: "Spring, 2021",
  },
  {
    jobtitle: "Website",
    where: "Poopazon.com",
    date: "Fall, 2021",
  },
  {
    jobtitle: "Marketing Campaign",
    where: "Mekanimals",
    date: "Winter, 2022",
  },
  {
    jobtitle: "Design",
    where: "Cherry Glamping Concept",
    date: "Summer, 2022",
  },
  {
    jobtitle: "App",
    where: "LightHouse",
    date: "Winter, 2023",
  },
];

const skills = [
  {
    name: "Music Production",
    value: 90,
  },
  {
    name: "Interior Design",
    value: 85,
  },
  {
    name: "Web Design",
    value: 60,
  },
  {
    name: "Architecture",
    value: 60,
  },
  {
    name: "Videography",
    value: 85,
  },
];

const services = [
  {
    title: "Creative Design and Production",
    description:
      "We merge our expertise in design, music production, and videography to offer a comprehensive suite of creative solutions to your problems. Whether you need engaging video content, captivating soundscapes, or innovative designs for your spaces, we can deliver a cohesive aesthetic and sensory experience tailored to your specific requirements.",
  },
  {
    title: "Digital Strategy and Web Development",
    description:
      "Leveraging our skills in web development and business planning, we focus on building robust online platforms for businesses, coupled with strategic planning to ensure your growth and success in the digital world. From creating visually stunning, user-friendly websites to formulating effective digital marketing strategies, we provide a solid foundation for your business to flourish online.",
  },
  {
    title: "Business Transformation",
    description:
      "With our strong background in business planning and our creative and technical prowess, we offer services aimed at transforming businesses from the inside out. This includes rebranding, enhancing operational efficiencies through design and technology, and developing multimedia content to elevate a brand's presence. Our holistic approach ensures that your business not only look appealing but also operate efficiently, addressing both internal and external needs.",
  },
];

const contactConfig = {
  YOUR_EMAIL: "milanstoj94@gmail.com",
  YOUR_FONE: "(414) 875 7909",
  description: "We'd love to hear from you.",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_o9c345o",
  YOUR_TEMPLATE_ID: "template_u0q14q9",
  YOUR_USER_ID: "STUO7C6Jep-fqCuU_",
};

const socialprofils = {
  github: "https://github.com",
  facebook: "https://facebook.com",
  linkedin: "https://linkedin.com",
  twitter: "https://twitter.com",
};
export {
  meta,
  dataabout,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
